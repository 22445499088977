.social-buttons {
  max-width: var(--width-limit);
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin: auto;
  display: flex;
}
/*# sourceMappingURL=social-buttons.cdaa64e4.css.map */
